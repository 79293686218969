import React from 'react'
import { DiscussionEmbed } from 'disqus-react';

const Disqus = (props) => {

  return (
    <div className="section-comments">
    <DiscussionEmbed
        shortname='tfttpresents'
        config={
            {
                url: props.url,
                identifier: props.id,
                title: props.title,
                language: 'en' //e.g. for Traditional Chinese (Taiwan)
            }
        }
    />
    </div>
  )
}

export default Disqus
