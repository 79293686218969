import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Disqus from '../components/disqus'
import PageTitle from '../components/page-title'
import Share from '../components/share'
import { formatDateAsHumanized, unescapePunctuation } from '../utilities/formatters'

export const BlogPostTemplate = ({
  content,
  categories,
  featured_media,
  tags,
  title,
  date,
  author,
  post,
}) => {

  const contentParsed = content.replace(/http:\/\/18.235.35.120/g, "https://content.tfttpresents.com").replace(/http:\/\/admin.tfttpresents.com/g, "https://content.tfttpresents.com");
  const parsed_date = new Date(date);

  const handleClick = (e) => {
    if(e.target.tagName == "IMG" && e.target.parentNode.parentNode.classList.contains('blocks-gallery-item')) {
      const caption = e.target.parentNode.querySelector('.blocks-gallery-item__caption').innerHTML;
      console.log(caption);
      window.dispatchEvent(new CustomEvent('lightboxOpen', {'detail': {'srcset': e.target.srcset, 'caption': caption}}));
    }
  }

  return (
    <div>
      <PageTitle
        categories={categories}
        tags={tags}
        title={unescapePunctuation(title)}
      />

      <aside className="post__meta">
        <small>By <Link to={`/author/${author.slug}`}>{author.name}</Link></small>
        <span className="text--spacer">•</span>
        <small>{formatDateAsHumanized(parsed_date)}</small>
        {categories && !!categories.length &&
          <ul className="post__tag-category post__tag-category--category">

            {categories.map((category, index) => {
              return (<li key={category.slug}><Link to={`/categories/${category.slug}`}>{category.name}</Link></li>)
            })}
          </ul>
        }
      </aside>

      <article className="content" role="article" onClick={handleClick} dangerouslySetInnerHTML={{ __html: contentParsed }} />

      <aside className="post__meta">
        {tags && !!tags.length &&
          <ul className="post__tag-category post__tag-category--tag">
          <li><span className="tag-category__head">Tagged:</span></li>
            {tags.map((tag, index) => {
              return (<li key={tag.slug}><Link to={`/tags/${tag.slug}`}>{tag.name}</Link></li>)
            })}
          </ul>
        }
        <Share
          title={post.title}
          path={post.path}
        />
      </aside>

      <Disqus
        id={post.id}
        title={post.title}
        url={`https://tfttpresents.com${post.path}`}
      />
    </div>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  title: PropTypes.string,
}

const BlogPost = ({ data }) => {
  const { wordpressPost: post } = data

  return (
    <Layout>
      <Helmet title={`${unescapePunctuation(post.title)} | Tales from the Tabletop`} />
      <BlogPostTemplate
        content={post.content}
        categories={post.categories}
        featured_media={post.featured_media}
        tags={post.tags}
        title={post.title}
        date={post.date}
        author={post.author}
        post={post}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  fragment PostFields on wordpress__POST {
    id
    slug
    content
    date(formatString: "MMMM DD, YYYY")
    title
  }
  query BlogPostByID($id: String!) {
    wordpressPost(id: { eq: $id }) {
      id
      title
      slug
      path
      content
      date(formatString: "MMMM DD, YYYY")
      categories {
        name
        slug
      }
      featured_media {
        source_url
      }
      tags {
        name
        slug
      }
      author {
        name
        slug
      }
    }
  }
`
