import React from 'react'

import Facebook from '../assets/images/social/facebook'
import Twitter from '../assets/images/social/twitter'
import Linkedin from '../assets/images/social/linkedin'

const Share = (props) => {

    return (
        <ul role="complementary" className="meta__share">
        <li><span className="share__head">Share:</span></li>
          <li>
            <a href={`http://www.facebook.com/sharer/sharer.php?u=https://www.tfttpresents.com${props.path}&title=${props.title}`} target="popup" className="social" title="Share this post on Facebook">
            <Facebook style={{"fill":"#1877F2", "width":"31.984", "height":"31.984"}} />
            </a>
          </li>
          <li>
            <a href={`http://twitter.com/intent/tweet?text=${props.title}&url=https://www.tfttpresents.com${props.path}`} target="popup" className="social" title="Share this post on Twitter">
            <Twitter style={{"fill":"#1DA1F2", "width":"31.984", "height":"31.984"}} />
            </a>
          </li>
        </ul>

    )

}

export default Share
