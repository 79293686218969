import React from 'react'
import Tile from './tiles/tile'

const PageTitle = (props) => {
  const media_url = props.featured_media ? props.featured_media.source_url : '';
  const palette = props.categories && (props.categories.filter(category => category.slug === "edge-of-the-world").length > 0) ? "edge-of-the-world" : "generic";
  return (
    <section className={`hero hero--title ${media_url ? 'hero--with-image' : ''}`} style={{'backgroundImage':`url(${media_url})`}}>
      <Tile
      color={media_url ? 'ffffff' : ''}
      className="hero__tile"
      delay={10}
      palette={palette}/>
      <h1>{props.title}</h1>
    </section>
  )
}

export default PageTitle
